import Footer from '@react/shared/components/Footer';
import Header from '@react/shared/components/Header/Header.container';
import { Link } from 'sly/system';

function Error({ statusCode }) {
  return (
    <>
      <Header />
      <div
        className='my-44 mx-l text-center'
        data-tp-id='gen-div-a7429b35-f3d9-4ed1-a045-fa0864584975'
      >
        <div
          className='font-t-m-azo'
          data-tp-id='gen-div-e564ea5d-f40e-4aac-aa58-2d6a33fa25c6'
        >
          Oops something went wrong. Please try again later.
        </div>
        <br className='mb-xl' />
        <div
          className='font-t-s-azo'
          data-tp-id='gen-div-336e9790-fba4-42bd-84af-48d238015bc7'
        >
          Error Code: {statusCode}
        </div>
        <br className='mb-xl' />
        <div data-tp-id='gen-div-a7657d44-e713-429b-805b-829ff2584d1e'>
          Head back to our{' '}
          <Link
            href='/'
            data-tp-id='gen-Link-c3b69481-f61f-44d1-b005-a3c0436495f0'
          >
            Homepage{' '}
          </Link>
          or{' '}
          <Link
            href='/contact-us'
            data-tp-id='gen-Link-2add9b0b-f345-458a-98b3-4115bcb3e07a'
          >
            Contact us
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
}

Error.getInitialProps = ({ res, _, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
// Error.getInitialProps = ({ res, req, err }) => {
//   const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

//   if (err) {
//     if (typeof window === 'undefined' && !isDev) {
//       const fs = require('fs');
//       try {
//         fs.appendFileSync(
//           '/var/log/next.apperr.log',
//           JSON.stringify({
//             ts: new Date().toISOString(),
//             status: statusCode,
//             url: req.url,
//             error: cleanError(err),
//             message: err.message,
//           }) + '\n',
//           { encoding: 'utf8' }
//         );
//       } catch (err) {
//         console.error(err);
//       }
//     }
//   }

//   return { statusCode };
// };

export default Error;
